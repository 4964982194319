import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useSdgMutation } from 'api';

export interface FieldError {
  row: number;
  fieldName: string;
  fieldValue: string;
  message: string;
  failureType: string;
}

export interface CreateBoard {
  boardName: string;
  labels?: string[];
  customFields?: Array<{ name: string; value: string }>;
}

export interface MutationArgs {
  mode: string;
  rows: Array<Record<string, string | undefined>>;
  createBoards?: CreateBoard[];
  validateOnly?: boolean;
}

export interface ValidateResponse {
  success: boolean;
  errors: FieldError[];
}

const combinedUploadMutation = gql`
  mutation combinedUpload($validateOnly: Boolean!, $request: CombinedUploadRequest!) {
    combinedUpload(validateOnly: $validateOnly, request: $request) {
      success
      errors {
        row
        fieldName
        fieldValue
        message
        failureType
      }
    }
  }
`;

export const useCombinedUploadMutation = (options?: UseMutationOptions<ValidateResponse, AxiosError, MutationArgs>) => {
  return useSdgMutation<ValidateResponse, AxiosError, MutationArgs, ValidateResponse>(
    async ({ mode, rows, createBoards, validateOnly = true }: MutationArgs, client): Promise<ValidateResponse> => {
      const response = await client.request<{ combinedUpload: ValidateResponse }>(combinedUploadMutation, {
        validateOnly,
        request: { mode, rows, createBoards },
      });
      return response.combinedUpload;
    },
    options,
  );
};
