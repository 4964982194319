export const PLAN_FEATURES = {
  starter: [
    'Combine your shipment and order data with hourly ETA updates from hundreds of shipping lines and airlines',
    'Triage and prioritize with delay and arrival email notifications',
    'Attach documents to associated shipments and orders',
    '3 shared dashboards for keeping customers, warehouses, suppliers, road hauliers and others in the loop',
  ],
  business: [
    '25 shared dashboards for keeping customers, warehouses, suppliers, road hauliers and others in the loop',
    'Demurrage and detention alerts and reporting',
    'Measure Scope 3 freight emissions',
  ],
  pro: [
    'Unlimited shared dashboards for keeping customers, warehouses, suppliers, road hauliers and others in the loop',
    'Analyze historical reliability across carriers, forwarders, routes and suppliers',
    'Compare container spot rates by route',
    'View upcoming sailing options and transit times from all shipping lines',
  ],
};
