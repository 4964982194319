import { NamedColor } from '@beacon-devops/components';
import { SearchMode } from '@beacon-types/cargo/mode';
import { UserPermissions } from '@features/auth/user/currentUser/auth/permissions/userPermissions';
import { CargoStatus, Mode, ModeOfTracking } from '@services/ShipmentDataGatewayService';
import { BolProcessingStatusText } from './constants';

export const PROCESSING_LABEL = 'Processing';
export const IN_TRANSIT_LABEL = 'In transit';

enum FilterGroup {
  arrivals = 'ARRIVALS',
  departures = 'DEPARTURES',
  none = 'NONE',
}

export enum JourneyStage {
  Processing = 'Processing',
  AtPol = 'AtPol',
  InTransit = 'InTransit',
  AtPoD = 'AtPoD',
  Haulage = 'Haulage',
  Delivered = 'Delivered',
}

export type CargoStatusConfig = {
  cargoStatusText: string;
  /**
   * This property allows you to calculate tooltip test based on user permissions provided
   */
  cargoStatusTooltipText: (permissions?: UserPermissions) => string;
  cargoGroup: FilterGroup;
  cargoJourneyStage: JourneyStage;
  cargoStatusChipColor: NamedColor;
};

type OceanStatuses =
  | CargoStatus.GatedOutEmpty
  | CargoStatus.AwaitingLoadAtOrigin
  | CargoStatus.LoadedAtOrigin
  | CargoStatus.EnrouteToPll
  | CargoStatus.GatedInFull
  | CargoStatus.LoadedAtPll
  | CargoStatus.InTransit
  | CargoStatus.ArrivedAtPdl
  | CargoStatus.BerthedAtPdl
  | CargoStatus.DischargedAtPdl
  | CargoStatus.GatedOutFull
  | CargoStatus.ArrivedAtYard
  | CargoStatus.EnrouteToDest
  | CargoStatus.ArrivedAtDest
  | CargoStatus.BerthedAtDest
  | CargoStatus.DischargedAtDest
  | CargoStatus.Delivered
  | CargoStatus.PresumedDelivered
  | CargoStatus.GatedInEmpty
  | CargoStatus.TrackingError;

type AirStatuses =
  | CargoStatus.Confirmed
  | CargoStatus.Received
  | CargoStatus.LoadedAtOrigin
  | CargoStatus.LoadedAtPll
  | CargoStatus.InTransit
  | CargoStatus.ArrivedAtPdl
  | CargoStatus.DischargedAtPdl
  | CargoStatus.ArrivedAtDest
  | CargoStatus.DischargedAtDest
  | CargoStatus.Collected
  | CargoStatus.PresumedDelivered;

type RoadStatuses =
  | CargoStatus.CreatedAwaitingCustomerData
  | CargoStatus.ReadyToTrack
  | CargoStatus.EnrouteToPickup
  | CargoStatus.AwaitingLoadAtOrigin
  | CargoStatus.InTransit
  | CargoStatus.ArrivedAtPdl
  | CargoStatus.ArrivedAtDest
  | CargoStatus.DepartedUnladenAtDest
  | CargoStatus.TrackingError;

/**
 * Aiming to be the one source of truth when we look into status - label, tooltip, color, group mappings.
 *
 * Intentionally kept as 'private' to module, access should be available through functions like `getCargoStatus` below.
 *
 * See https://beacon-freight.atlassian.net/wiki/spaces/EN/pages/1856372763/Tracking+Search+Results+SCV+Mapping#Current-status
 */
const oceanCargoStatusConfigMappings: Record<OceanStatuses, CargoStatusConfig> = {
  [CargoStatus.GatedOutEmpty]: {
    cargoStatusText: 'Gated out empty',
    cargoStatusTooltipText: () => 'Container has gated out empty of container yard or PoL.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.AwaitingLoadAtOrigin]: {
    cargoStatusText: 'At pick-up',
    cargoStatusTooltipText: () => 'Container is at pick-up point at factory.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.Processing,
  },
  [CargoStatus.LoadedAtOrigin]: {
    cargoStatusText: 'Loaded at PoL',
    cargoStatusTooltipText: () => 'Container has loaded at PoL.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.EnrouteToPll]: {
    cargoStatusText: 'En-route to PoL',
    cargoStatusTooltipText: () => 'Container is en-route to PoL from an inland yard.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.Processing,
  },
  [CargoStatus.GatedInFull]: {
    cargoStatusText: 'Gated in full',
    cargoStatusTooltipText: () => 'Container has gated in full at PoL.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.LoadedAtPll]: {
    cargoStatusText: 'Loaded at PoL',
    cargoStatusTooltipText: () => 'Container has loaded at PoL.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.InTransit]: {
    cargoStatusText: IN_TRANSIT_LABEL,
    cargoStatusTooltipText: () => 'Container has departed from PoL.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'info',
    cargoJourneyStage: JourneyStage.InTransit,
  },
  [CargoStatus.ArrivedAtPdl]: {
    cargoStatusText: 'Arrived at PoD',
    cargoStatusTooltipText: () => 'Container has arrived and/or berthed at PoD.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.BerthedAtPdl]: {
    cargoStatusText: 'Arrived at PoD',
    cargoStatusTooltipText: () => 'Container has arrived and/or berthed at PoD.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.DischargedAtPdl]: {
    cargoStatusText: 'Discharged at PoD',
    cargoStatusTooltipText: () => 'Container has discharged at PoD.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.GatedOutFull]: {
    cargoStatusText: 'Gated out full',
    cargoStatusTooltipText: () => 'Container has gated out full at PoD.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Haulage,
  },
  [CargoStatus.ArrivedAtYard]: {
    cargoStatusText: 'Arrived at yard',
    cargoStatusTooltipText: () => 'Container has arrived at final yard.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Haulage,
  },
  [CargoStatus.EnrouteToDest]: {
    cargoStatusText: 'Arrived at yard',
    cargoStatusTooltipText: () => 'Container has arrived at final yard.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Haulage,
  },
  [CargoStatus.ArrivedAtDest]: {
    cargoStatusText: 'Arrived at Dest',
    cargoStatusTooltipText: () => 'Container has arrived and/or berthed at destination.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
  [CargoStatus.BerthedAtDest]: {
    cargoStatusText: 'Arrived at Dest',
    cargoStatusTooltipText: () => 'Container has arrived and/or berthed at destination.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
  [CargoStatus.DischargedAtDest]: {
    cargoStatusText: 'Discharged at Dest',
    cargoStatusTooltipText: () => 'Container has discharged at destination.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
  [CargoStatus.Delivered]: {
    cargoStatusText: 'Delivered',
    cargoStatusTooltipText: () =>
      "Container has been delivered at carrier shared location or presumed delivered (if Warehouse ETA has passed today's date).",
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
  [CargoStatus.PresumedDelivered]: {
    cargoStatusText: 'Delivered',
    cargoStatusTooltipText: () =>
      "Container has been delivered at carrier shared location or presumed delivered (if Warehouse ETA has passed today's date).",
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
  [CargoStatus.GatedInEmpty]: {
    cargoStatusText: 'Gated in empty',
    cargoStatusTooltipText: () => 'Container has gated in empty',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
  [CargoStatus.TrackingError]: {
    cargoStatusText: 'Not tracking',
    cargoStatusTooltipText: (permissions) =>
      permissions?.canRequestOceanShipmentTrackingWithoutCarrier
        ? 'An issue has occurred with this shipment, and it is not currently tracking.'
        : 'The container and carrier name combination did not produce any tracking results.' +
          ' Please delete this container and try uploading it again with the correct carrier name',
    cargoGroup: FilterGroup.none,
    cargoStatusChipColor: 'error',
    cargoJourneyStage: JourneyStage.Processing,
  },
};

const oceanBolPlaceholderStatusConfig: Record<CargoStatus.Unspecified | CargoStatus.TrackingError, CargoStatusConfig> =
  {
    [CargoStatus.Unspecified]: {
      cargoGroup: FilterGroup.none,
      cargoJourneyStage: JourneyStage.Processing,
      cargoStatusText: BolProcessingStatusText,
      cargoStatusChipColor: 'secondary',
      cargoStatusTooltipText: () =>
        'Your bill of lading is awaiting container assignment by the carrier. The status will update automatically once assigned.',
    },
    [CargoStatus.TrackingError]: {
      cargoStatusText: 'Not tracking',
      cargoStatusTooltipText: () => '',
      cargoGroup: FilterGroup.none,
      cargoStatusChipColor: 'error',
      cargoJourneyStage: JourneyStage.Processing,
    },
  };

const airStatusConfigMappings: Record<AirStatuses, CargoStatusConfig> = {
  [CargoStatus.Confirmed]: {
    cargoStatusText: 'Confirmed',
    cargoStatusTooltipText: () => 'Booking confirmed at origin airport',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.Received]: {
    cargoStatusText: 'Awaiting loading',
    cargoStatusTooltipText: () => 'Received from shipper at origin airport.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.LoadedAtOrigin]: {
    cargoStatusText: 'Loaded',
    cargoStatusTooltipText: () => 'Loaded At Origin Airport',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.LoadedAtPll]: {
    cargoStatusText: 'Loaded',
    cargoStatusTooltipText: () => 'Loaded At Origin Airport',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.InTransit]: {
    cargoStatusText: IN_TRANSIT_LABEL,
    cargoStatusTooltipText: () => 'Departed from origin airport.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'info',
    cargoJourneyStage: JourneyStage.InTransit,
  },
  [CargoStatus.ArrivedAtPdl]: {
    cargoStatusText: 'Arrived',
    cargoStatusTooltipText: () => 'Arrived at destination airport.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.DischargedAtPdl]: {
    cargoStatusText: 'Offloaded',
    cargoStatusTooltipText: () => 'Ready for collection at destination airport.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.ArrivedAtDest]: {
    cargoStatusText: 'Arrived',
    cargoStatusTooltipText: () => 'Arrived at destination airport.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.DischargedAtDest]: {
    cargoStatusText: 'Offloaded',
    cargoStatusTooltipText: () => 'Ready for collection at destination airport.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.Collected]: {
    cargoStatusText: 'Collected',
    cargoStatusTooltipText: () => 'Collected from destination airport.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Haulage,
  },
  [CargoStatus.PresumedDelivered]: {
    cargoStatusText: 'Delivered',
    cargoStatusTooltipText: () => 'AWB assumed delivered to destination warehouse as ETA is in the past.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.Delivered,
  },
};

const roadStatusConfigMappings: Record<RoadStatuses, CargoStatusConfig> = {
  [CargoStatus.CreatedAwaitingCustomerData]: {
    cargoStatusText: 'Awaiting licence plate',
    cargoStatusTooltipText: () => 'Add a licence plate to this shipment to begin tracking.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'secondary',
    cargoJourneyStage: JourneyStage.Processing,
  },
  [CargoStatus.ReadyToTrack]: {
    cargoStatusText: 'Ready to track',
    cargoStatusTooltipText: () => 'Awaiting tracking data for this shipment.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.EnrouteToPickup]: {
    cargoStatusText: 'En-route to pick-up',
    cargoStatusTooltipText: () => 'Tracking has begun and truck is en-route to pick up.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.AwaitingLoadAtOrigin]: {
    cargoStatusText: 'At pick-up',
    cargoStatusTooltipText: () => 'Truck has arrived at pick up location.',
    cargoGroup: FilterGroup.departures,
    cargoStatusChipColor: 'warning',
    cargoJourneyStage: JourneyStage.AtPol,
  },
  [CargoStatus.InTransit]: {
    cargoStatusText: IN_TRANSIT_LABEL,
    cargoStatusTooltipText: () => 'Truck has departed from pick up and is heading to the drop off location.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'info',
    cargoJourneyStage: JourneyStage.InTransit,
  },
  [CargoStatus.ArrivedAtPdl]: {
    cargoStatusText: 'At drop-off',
    cargoStatusTooltipText: () => 'Truck has arrived at drop off location.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.ArrivedAtDest]: {
    cargoStatusText: 'At drop-off',
    cargoStatusTooltipText: () => 'Truck has arrived at drop off location.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.DepartedUnladenAtDest]: {
    cargoStatusText: 'Completed',
    cargoStatusTooltipText: () => 'Truck has departed from drop off location, and shipment is complete.',
    cargoGroup: FilterGroup.arrivals,
    cargoStatusChipColor: 'success',
    cargoJourneyStage: JourneyStage.AtPoD,
  },
  [CargoStatus.TrackingError]: {
    cargoStatusText: 'Not tracking',
    cargoStatusTooltipText: () => 'An issue has occurred with this shipment, and it is not currently tracking.',
    cargoGroup: FilterGroup.none,
    cargoStatusChipColor: 'error',
    cargoJourneyStage: JourneyStage.Processing,
  },
} satisfies Record<RoadStatuses, CargoStatusConfig>;

// Type guard to check if the status is only applicable to Mbol placeholder cargos
// We know that placeholder bol cargo can only be Unspecified or TrackingError statuses
// This guard allows us to give this information to the typescript compiler
const isBolPlaceholderStatus = (status: CargoStatus): status is CargoStatus.Unspecified | CargoStatus.TrackingError => {
  return status in oceanBolPlaceholderStatusConfig;
};

const isOceanStatus = (status: CargoStatus): status is OceanStatuses => {
  return status in oceanCargoStatusConfigMappings;
};

const isAirStatus = (status: CargoStatus): status is AirStatuses => {
  return status in airStatusConfigMappings;
};

const isRoadStatus = (status: CargoStatus): status is RoadStatuses => {
  return status in roadStatusConfigMappings;
};

export const getCargoStatus = (
  status: CargoStatus,
  mode: SearchMode | Mode = SearchMode.Ocean,
  modeOfTracking?: ModeOfTracking,
  placeholder?: boolean,
): CargoStatusConfig => {
  const processingStatusConfig = {
    cargoStatusText: PROCESSING_LABEL,
    cargoStatusTooltipText: () => '',
    cargoStatusChipColor: 'secondaryShades.2',
    cargoGroup: FilterGroup.none,
    cargoJourneyStage: JourneyStage.Processing,
  };

  let config = processingStatusConfig;

  if (mode === SearchMode.Air && isAirStatus(status)) {
    config = airStatusConfigMappings[status];
  }

  if (mode === SearchMode.Ocean || mode === SearchMode.All) {
    if (modeOfTracking === ModeOfTracking.Mbol && placeholder && isBolPlaceholderStatus(status)) {
      config = oceanBolPlaceholderStatusConfig[status];
    } else if (isOceanStatus(status)) {
      config = oceanCargoStatusConfigMappings[status];
    }
  }

  if (mode === SearchMode.Road && isRoadStatus(status)) {
    config = roadStatusConfigMappings[status];
  }

  return {
    ...config,
    // I don't like this type assertion but legacy code depends on this
    cargoStatusChipColor: config.cargoStatusChipColor as NamedColor,
  };
};

/** get each cargo status per mode */
export const getCargoStatusesByMode = (mode: Mode = Mode.Ocean): string[] => {
  if (mode === Mode.Ocean) {
    return [...Object.keys(oceanCargoStatusConfigMappings), CargoStatus.Unspecified];
  }

  if (mode === Mode.Air) {
    return [...Object.keys(airStatusConfigMappings), CargoStatus.Unspecified];
  }

  if (mode === Mode.Road) {
    return [...Object.keys(roadStatusConfigMappings), CargoStatus.Unspecified];
  }

  return [];
};
